export const capitalStackInteractions = {
  order: 3,
  name: "Capital stack interactions",
  keywords: "deal owner investment debt equity common preferred role contact",
  subcategory: "Investments",
  markdown: `# Capital stack interactions

  In Tower Hunt, the {% inlineRouterLink articleId="understand-the-capital-stack" %}capital stack{% /inlineRouterLink %} is an interactive tool that helps you discover {% inlineRouterLink articleId="the-role-of-investments" %}investment{% /inlineRouterLink %} information and add new data. Here are all of the things you can do:

  ## Open the Deal Hub

  Click the "View & Add Deals" button to open the selected asset in the {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %}.

  ![View and Add Deals button screenshot](https://assets.towerhunt.com/site/capitalStackAddOwner.png)

  ## Add an owner

  If you are viewing an asset as of a date when Tower Hunt doesn't know the ownership, you can add owners by clicking {% inlineAppIcon iconName="pencil" /%}**Add owner**:

  ![Add owner screenshot](https://assets.towerhunt.com/site/capitalStackAddOwner.png)

  {% callout type="caution" %}
  **Important:** In Tower Hunt, ownership is derived from investment activity. So, you are really {% inlineRouterLink articleId="create-an-investment" %}creating an investment{% /inlineRouterLink %} here.
  {% /callout %}

  ## Trigger an investment

  If the capital stack contains layers as of the date you are viewing, click any {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** to reveal context-aware investments that can be triggered.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/triggerInvestment0.mp4" marginStyles="my-4" /%}

  {% callout type="tip" %}
  **Tip:** Looking for the different types of investments in Tower Hunt, as well as how they appear in the capital stack? We put together an {% inlineRouterLink articleId="investment-types" %}illustrated list of investment types{% /inlineRouterLink %}.
  {% /callout %}

  ## Add a debt layer

  Click the **+ Loan** button that appears between layers to begin adding a new loan.

  ![Add loan button screenshot](https://assets.towerhunt.com/site/capitalStackAddDebtLayer.png)

  {% callout type="caution" %}
  **Important:** In Tower Hunt, originating a loan is a {% inlineRouterLink articleId="investment-types" sectionName="Loan origination" %}type of investment{% /inlineRouterLink %}. So, you are really {% inlineRouterLink articleId="create-an-investment" %}creating an investment{% /inlineRouterLink %} here.
  {% /callout %}

  ## Edit equity type

  There are important differences between {% inlineRouterLink articleId="understand-the-capital-stack" sectionName="Types of capital" %}common and preferred equity positions{% /inlineRouterLink %}. Among other things, they carry different levels of risk and control. To designate which type of equity a company holds, click the "Common" or "Preferred" datapoint under an **equity** owner's name, then click {% inlineAppIcon iconName="wrench" /%}**Edit**.

  ![Equity type screenshot](https://assets.towerhunt.com/site/editEquityType0.png)

  ## Add company involvement roles

  Companies are involved with properties and investments in different ways. Tower Hunt tracks these company involvement roles on a project-by-project basis. To add a role to a company, click {% inlineAppIcon iconName="pencil" /%}**Add a role** under a company's name.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/capitalStackAddRole0.mp4" marginStyles="my-4" /%}

  ## Add contacts

  Contacts work on properties and investments. Knowing these assignments provides valuable context into a contact's areas of expertise. Tower Hunt tracks these contact involvements on a project-by-project basis. To add a contact to a project, click the {% inlineAppIcon iconName="userPlus" /%}**Add contact button** under a company's name.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/capitalStackAddContacts0.mp4" marginStyles="my-4" /%}

  ## View a player's source investment

  Click the {% inlineAppIcon iconName="arrowsLeftRight" /%}**Deal actions menu** that appears to the right of a company, then choose {% inlineAppIcon iconName="dollar" /%}**View source investment**. The {% inlineRouterLink articleId="deal-hub" %}Deal Hub{% /inlineRouterLink %} opens, displaying the investment that put this player into the capital stack.

  ![View source investment screenshot](https://assets.towerhunt.com/site/viewSourceInvestment0.png)

  ## View a nested capital stack

  If the capital stack contains debt layers, click **View nested stack** under any loan's name to explore or add to the nested capital stack.

  ![Nested capital stack screenshot](https://assets.towerhunt.com/site/nestedCapitalStack0.png)

  ## Interact with datapoints

  Click any {% inlineRouterLink articleId="what-is-a-datapoint?" %}datapoint{% /inlineRouterLink %} to access its full {% inlineRouterLink %}action menu{% /inlineRouterLink %}. Use this to:

  - {% inlineAppIcon iconName="dollar" /%} {% inlineRouterLink %}Unlock datapoints{% /inlineRouterLink %}
  - {% inlineAppIcon iconName="pencilSquare" /%} {% inlineRouterLink %}Read and write notes{% /inlineRouterLink %}
  - {% inlineAppIcon iconName="star" /%} {% inlineRouterLink articleId="create-a-task" %}Create a task{% /inlineRouterLink %}
  - {% inlineAppIcon iconName="documentCheck" /%} {% inlineRouterLink %}View related tasks{% /inlineRouterLink %}`,
};
